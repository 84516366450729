import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import RestaurantPageAddressButton from "./RestaurantPageAddressButton";
import FrameButton from "../../../components/buttons/FrameButton";
import {FaShoppingCart} from "react-icons/fa";
import {RestaurantContext} from "../context/RestaurantContext";
import {refreshMenuOrderCart} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import DevourTopNavUserData from "@/components/DevourTopNavUserData";
import SignUpToast from "@/components/SignUpToast";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {IStore} from "@/redux/defaultStore";

interface Props {
    toggleShowCartPanel: () => void;
}

function RestaurantPageTopBar(props: Props): ReactElement {
    const dispatch = useDispatch();
    const {menuOrderId} = useContext(RestaurantContext);
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const isMenuOrderUpdating = useSelector((store: IStore) => store.metaStore.isMenuOrderUpdating);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const sumQuantity: number = menuOrder?.orderItems.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);
    function handleToastDismissal() {
        setShowToast(false);
    }
    function handleToastShow() {
        setShowToast(true);
    }

    function onCartClick(): void {
        dispatch(refreshMenuOrderCart());
        props.toggleShowCartPanel();
    }

    return (
        <>
            <SignUpToast
                message='Join DevourGO and start your GoFriends journey!'
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <div className="restaurant-page-top-bar">
                <div className="restaurant-page-top-bar_logo">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-01.webp`}
                        alt="DevourGO Logo"
                    />
                </div>
                <div className="restaurant-page-top-bar_cart">
                    <div className="restaurant-page-top-bar_cart_button">
                        <FrameButton
                            <ButtonHTMLAttributes<HTMLButtonElement>>
                            onClick={onCartClick}
                            color="purple"
                            size="narrow"
                            leftIcon={FaShoppingCart}
                            forwardProps={{type: "button"}}
                        >
                            {isMenuOrderUpdating
                                ? <div className="loading-spinner"/>
                                : sumQuantity || 0}
                        </FrameButton>
                    </div>
                </div>
                <div className="restaurant-page-top-bar_account">
                    <DevourTopNavUserData onShowSignInToast={handleToastShow} dropdownAnchorElement=".restaurant-page-top-bar" avatarOnly hideWalletOnMobile={true}/>
                </div>
                <div className="restaurant-page-top-bar_address">
                    <RestaurantPageAddressButton/>
                </div>
            </div>
        </>
    );
}

export default RestaurantPageTopBar;
